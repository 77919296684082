<template>
  <section id="formulario_contacto">
    <v-col cols="12" md="10" class="mx-auto">
    <div class="row">
      <v-col cols="12">
        <h1>Solicito mi inscripción</h1>
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <v-row>

            <v-col cols="12" sm="4" md="4">
              <ValidationProvider name="curso" rules="required" v-slot="{ errors }">
                <v-select
                  :items="areas"
                  item-text="description"
                  item-value="id"
                  v-model="area"
                  filled
                  label="Modalidad"
                  :error-messages="errors"
                ></v-select>

              </ValidationProvider>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <ValidationProvider name="curso" rules="required" v-slot="{ errors }">
                <v-select
                  :items="cursosFiltrados"
                  item-text="name"
                  item-value="id"
                  v-model="curso"
                  filled
                  label="Curso"
                  :error-messages="errors"
                  @change="changeCurso()"
                ></v-select>

              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider name="fecha" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="date"
                  label="Fecha de Inicio"
                  prepend-icon="mdi-calendar"
                  name="fecha"
                  readonly
                  filled
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="errors"
                  @change="$refs.menu.save(date)"
                ></v-text-field>
                 </ValidationProvider>
              </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                  locale="es"
                  :first-day-of-week="0"
                  @change="$refs.menu.save(date)"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                </v-date-picker>
             
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <ValidationProvider name="horario" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="horario"
                  name="horario"
                  label="Horario"
                  filled
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" sm="8" md="8">
              <ValidationProvider name="nombre" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="name"
                  name="name"
                  label="Nombre y Apellido"
                  filled
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="DNI" rules="required|numeric" v-slot="{ errors }">
                <v-text-field
                  v-model="DNI"
                  name="DNI"
                  label="DNI (Sin puntos)"
                  type="number"
                  filled
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="correo" rules="email|required" v-slot="{ errors }">
                <v-text-field
                  v-model="email"
                  name="correo"
                  label="Correo"
                  type="email"
                  filled
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            
            

            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="teléfono" rules="required|numeric" v-slot="{ errors }">
                <v-text-field
                  v-model="phone"
                  name="phone"
                  label="Teléfono"
                  filled
                  type="number"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="domicilio" rules="" v-slot="{ errors }">
                <v-text-field
                  v-model="domicilio"
                  name="domicilio"
                  label="Domicilio"
                  filled
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="localidad" rules="" v-slot="{ errors }">
                <v-text-field
                  v-model="localidad"
                  name="localidad"
                  label="Localidad"
                  filled
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="provincia" rules="" v-slot="{ errors }">
                <v-text-field
                  v-model="provincia"
                  name="provincia"
                  label="Provincia"
                  filled
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="como" rules="" v-slot="{ errors }">
                <v-select
                  :items="['Google', 'Facebook','Avisos','Otro']"
                  label="¿Cómo nos conociste?"
                  v-model="como"
                  name="como"
                  filled
                  :error-messages="errors"
                ></v-select>
              </ValidationProvider>
            </v-col>

            <v-col cols="12">
              <ValidationProvider name="comentario" rules="" v-slot="{ errors }">
                <v-text-field
                  v-model="comentario"
                  name="comentario"
                  label="Comentario"
                  filled
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

          </v-row>
          <p class="contact-text font-weight-bold text-center grey--text text--darken-1">
            <v-spacer class="mb-12"></v-spacer>
            <v-btn
              color="#5ebd99"
              rounded
              class="elevation-0 text-center px-8"
              dark
              :loading="loading"
              @click="passes(sendForm)"
              >Enviar</v-btn>
          </p>
        </ValidationObserver>
      </v-col>
      
      <Tcig :curso.sync="curso"></Tcig>
    </div>
    </v-col>
  </section>
</template>
<script>
import Swal from "sweetalert2";
import { mapState,mapGetters } from 'vuex'
import Tcig from '../../components/TciGComponent.vue'

export default {
  components:{
Tcig
  },
  data() {
    return {    
      area: null, 
      curso: null,
      name: null,
      email: null,
      phone: null,
      DNI: null,
      loading: false,
      date: null,
      menu: false,
      horario: null,
      domicilio: null,
      localidad: null,
      provincia: null,
      como: null,
      comentario: null,
      tci: null,

    };
  },
  metaInfo () {
    return {
      meta: [
        { name: 'description', content: 'Inscripción de curso en IDIP' }
      ]
    }
  },
  computed:{
    ...mapState('home',['cursos_activos','areas']),
    ...mapGetters('home',['tcig']),
    cursosFiltrados(){
      if (this.cursos_activos && this.area) {
        return this.cursos_activos.filter((element)=>element.area.id == this.area)
      }
      return []
    }
  },
  watch:{
    cursos: 'llenarInput',
  },
  created(){
    window.scrollTo(0,0)
    this.llenarInput()
  },
  methods: {

    nameSelect(i){
      return i.name + ' ('+ i.area.description +') '
    },
    changeCurso(){
      this.$forceUpdate();
    },
    llenarInput(){
       if (this.$route.query.course) {
        this.cursos.filter((element)=> {
          if (element.name == this.$route.query.course) {
            this.curso = element.id
          }
        });
      }
      this.$forceUpdate();

    },
    async sendForm(){
      this.loading = true

      var obj = {
        curso     : this.curso,
        name      : this.name,
        email     : this.email,
        phone     : this.phone,
        date      : this.date,
        horario   : this.horario,
        domicilio : this.domicilio,
        localidad : this.localidad,
        provincia : this.provincia,
        como      : this.como,
        comentario: this.comentario,
        DNI: this.DNI
      }
      try{
        const response = await this.$store.dispatch("contact/INSCRIPCION", obj);
        if (response.data.status) {
          this.loading = false
          // gtag('event', 'Inscripcion', {'event_category': 'Inscripcion','event_action': 'Curso', 'event_label': this.curso, 'value': ''});
          this.$gtag.event('Inscripcion', {'event_category': 'Inscripcion','event_action': 'Curso', 'event_label': this.curso, 'value': ''})
          Swal.fire('Inscripción enviada', response.data.mensaje, 'success').then(()=>{

            this.$router.push('/')
          })
        }else{
          Swal.fire('Opps...', response.data.mensaje, 'error')
        }
      }catch (e){
        console.log(e)
      }

    }
  },
};
</script>
<style>

</style>