var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"formulario_contacto"}},[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"row"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Solicito mi inscripción")]),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"curso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.areas,"item-text":"description","item-value":"id","filled":"","label":"Modalidad","error-messages":errors},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"curso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.cursosFiltrados,"item-text":"name","item-value":"id","filled":"","label":"Curso","error-messages":errors},on:{"change":function($event){return _vm.changeCurso()}},model:{value:(_vm.curso),callback:function ($$v) {_vm.curso=$$v},expression:"curso"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"fecha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Inicio","prepend-icon":"mdi-calendar","name":"fecha","readonly":"","filled":"","error-messages":errors},on:{"change":function($event){return _vm.$refs.menu.save(_vm.date)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"es","first-day-of-week":0},on:{"change":function($event){return _vm.$refs.menu.save(_vm.date)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"horario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"horario","label":"Horario","filled":"","error-messages":errors},model:{value:(_vm.horario),callback:function ($$v) {_vm.horario=$$v},expression:"horario"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","label":"Nombre y Apellido","filled":"","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"DNI","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"DNI","label":"DNI (Sin puntos)","type":"number","filled":"","error-messages":errors},model:{value:(_vm.DNI),callback:function ($$v) {_vm.DNI=$$v},expression:"DNI"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"correo","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"correo","label":"Correo","type":"email","filled":"","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"teléfono","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"phone","label":"Teléfono","filled":"","type":"number","error-messages":errors},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"domicilio","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"domicilio","label":"Domicilio","filled":"","error-messages":errors},model:{value:(_vm.domicilio),callback:function ($$v) {_vm.domicilio=$$v},expression:"domicilio"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"localidad","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"localidad","label":"Localidad","filled":"","error-messages":errors},model:{value:(_vm.localidad),callback:function ($$v) {_vm.localidad=$$v},expression:"localidad"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"provincia","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"provincia","label":"Provincia","filled":"","error-messages":errors},model:{value:(_vm.provincia),callback:function ($$v) {_vm.provincia=$$v},expression:"provincia"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"como","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":['Google', 'Facebook','Avisos','Otro'],"label":"¿Cómo nos conociste?","name":"como","filled":"","error-messages":errors},model:{value:(_vm.como),callback:function ($$v) {_vm.como=$$v},expression:"como"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"comentario","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"comentario","label":"Comentario","filled":"","error-messages":errors},model:{value:(_vm.comentario),callback:function ($$v) {_vm.comentario=$$v},expression:"comentario"}})]}}],null,true)})],1)],1),_c('p',{staticClass:"contact-text font-weight-bold text-center grey--text text--darken-1"},[_c('v-spacer',{staticClass:"mb-12"}),_c('v-btn',{staticClass:"elevation-0 text-center px-8",attrs:{"color":"#5ebd99","rounded":"","dark":"","loading":_vm.loading},on:{"click":function($event){return passes(_vm.sendForm)}}},[_vm._v("Enviar")])],1)]}}])})],1),_c('Tcig',{attrs:{"curso":_vm.curso},on:{"update:curso":function($event){_vm.curso=$event}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }