<template>
  <v-col  cols="12" v-html="data" >

  </v-col>

</template>

<script>
import { mapState,mapGetters } from 'vuex'

export default {
  name: 'tciG',
  props: ['curso'],
  components: {

  },
  data(){
    return{
      data: null,
    }
  },
  computed:{
    ...mapState('home',['cursos']),
    ...mapGetters('home',['tcig'])
  },
  watch:{
    curso: 'changeCurso',
    tcig: 'llenartcig'
  },
  mounted(){

    if (this.curso) {
      this.changeCurso()
    }
  },
  methods:{
    llenartcig(){

      if (this.data == null) {
        this.data = this.tcig
      }
    },
    changeCurso(){

      if (this.curso) {
        this.cursos.filter((element)=> {
          if (element.id == this.curso) {
            if (element.tci != null) {
              this.data = element.tci
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">

</style>
